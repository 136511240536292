@tailwind base;
@tailwind components;
@tailwind utilities;

/**
 Components you use everywhere: create a class for them - buttons, form inputs, tables
 Components repeated on the same page: render them with an array (and only write their classes once) - messages on chat app
 One-off components: write their classes once
*/

@layer base {
	:root {
		/* Muted colors */
		--muted-50: 210 100% 98%;
		--muted-100: 210 65% 96%;
		--muted-200: 210 40% 92%;
		--muted-300: 210 33% 84%;
		--muted-400: 210 24% 66%;
		--muted-500: 210 25% 47%;
		--muted-600: 210 25% 35%;
		--muted-700: 210 29% 27%;
		--muted-800: 210 33% 18%;
		--muted-900: 210 54% 11%;

		/* Additional colors */
		--background: 210 65% 96%;
		--foreground: 211 29% 27%;
		--primary: 198 67% 50%;
		--primary-foreground: 0 0% 100%;
		--primary-dark: 198 57% 34%;
		--primary-light: 198 74% 73%;
		--primary-degrade: 199 84% 30%;
		--secondary: 175 47% 49%;
		--secondary-foreground: 123 55% 19%;
		--muted: 240 20% 78%;
		--muted-foreground: 214 27% 24%;
		--accent: 210 40% 92%;
		--accent-foreground: 211 29% 27%;
		--danger: 351 79% 43%;
		--danger-foreground: 0 0% 100%;
		--warning: 17 76% 56%;
		--warning-foreground: 0 0% 100%;
		--success: 160 60% 68%;
		--accent-magenta: 327 36% 54%;
		--accent-rose: 340 75% 49%;
		--info: 180 67% 50%;
		--border: 210 33% 84%;
		--input: 210 33% 84%;
		--ring: 199 95% 53%;
		--grad-sat: 199 84% 30%;
		--grad-accent: 172 46% 77%;
	}
}

@layer base {
	* {
		@apply thin-scrollbar border-border;
	}
	body {
		@apply bg-background text-foreground;
	}
	html {
		@apply text-slate-900;
	}
}

@layer components {
	.container1 {
		@apply rounded-xl bg-white shadow ring-1 ring-muted-900/10;
	}
	.text1 {
		@apply text-xl font-bold lg:text-2xl xl:text-3xl 2xl:text-5xl 4xl:text-7xl;
	}
	.text2 {
		@apply text-lg md:text-xl lg:text-2xl 2xl:text-3xl 3xl:text-4xl 4xl:text-5xl;
	}
	.text3 {
		@apply text-base lg:text-lg xl:text-xl 2xl:text-3xl 4xl:text-5xl;
	}

	.text4 {
		@apply text-sm lg:text-base xl:text-lg 2xl:text-2xl 4xl:text-4xl;
	}

	.sidebar-item-active {
		@apply flex items-center gap-1.5 rounded-xl bg-[color:var(--color-muted-300)] px-2
		py-3 text-slate-700 hover:bg-[color:var(--color-muted-200)];
	}

	.sidebar-item-not-active {
		@apply flex items-center gap-1.5 rounded-xl px-2
		py-3 text-slate-700 hover:bg-[color:var(--color-muted-200)];
	}

	.subnav-item-active {
		@apply sidebar-item-active w-96 justify-center;
	}

	.subnav-item-not-active {
		@apply sidebar-item-not-active w-96 justify-center;
	}

	.toolbar-button {
		@apply flex select-none items-center justify-center gap-1
		whitespace-nowrap rounded-xl bg-[color:var(--color-primary)] px-3 py-1 text-lg font-medium text-white
		hover:cursor-pointer hover:bg-primary disabled:cursor-not-allowed;
	}

	.toolbar {
		@apply flex w-full flex-wrap gap-2;
	}

	.degrade-r {
		@apply bg-gradient-to-r from-primary to-[color:var(--color-secondary)];
	}

	.degrade-r-light {
		@apply bg-gradient-to-r from-[color:var(--color-secondary)] to-[color:var(--color-accent)];
	}

	.slidePanelFromRight {
		@apply absolute right-0 z-50 h-full w-96
		animate-[slideIn_ease-in-out_0.3s_backwards] overflow-y-scroll rounded-l-xl bg-white pb-12 shadow-xl;
	}

	.panel-form-title {
		@apply formatted-string w-full border-b border-slate-400
		p-1 text-center text-lg font-medium text-slate-700;
	}

	.form-container {
		@apply inline-flex flex-wrap gap-2.5;
	}

	.form-error {
		@apply field-error;
	}

	.field-error {
		@apply sm-text w-full text-center tracking-wide text-danger;
	}

	.avoid-page-break {
		break-inside: avoid;
		page-break-inside: avoid; /* For Firefox */
	}
}

@layer utilities {
	.filter-none {
		filter: none;
	}
	.filter-slatescale {
		filter: slatescale(100%);
	}

	.square {
		aspect-ratio: 1/1;
	}
	/* Chrome, Safari and Opera */
	.no-scrollbar::-webkit-scrollbar {
		display: none;
	}

	.no-scrollbar {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
	.thin-scrollbar::-webkit-scrollbar {
		width: 2px; /* Adjust as needed */
		height: 2px; /* Adjust as needed */
	}

	.thin-scrollbar::-webkit-scrollbar-thumb {
		background-color: #b9b6b6; /* Adjust color as needed */
		border-radius: 999px; /* Fully rounded scrollbar thumb */
		border: 2px solid transparent; /* Space between thumb and track */
		background-clip: content-box; /* Ensures border doesn't overlap */
		background-clip: padding-box; /* Prevent thumb background overflow */
	}

	.thin-scrollbar::-webkit-scrollbar-thumb:hover {
		background-color: #555; /* Adjust color as needed */
	}

	.thin-scrollbar::-webkit-scrollbar-track {
		background-color: #f1f1f1; /* Adjust track color */
		border-radius: 999px; /* Match thumb roundness */
		margin: 2px; /* Avoid clipping at edges */
	}

	.thin-scrollbar {
		scrollbar-width: thin; /* Firefox */
		scrollbar-color: #b9b6b6 #f1f1f1; /* Firefox, adjust colors as needed */
		-ms-overflow-style: none; /* IE and Edge */
	}
}
